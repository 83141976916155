import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { Geofence, secureCompany, secureGeofence } from '../../api/types'
import { updateGeofence } from '../../api/geofences'
import { getAll, updateItem } from '../../api/api_functions'

let geofences: Geofence[] = []

let initialState = {
  geofences: geofences,
  status: 'idle',
  error: '',
}

export const fetchGeofences = createAsyncThunk(
  'geofences/fetchGeofences',

  async (obj: secureCompany) => {
    const response = await getAll<Geofence>(
      'geofence',
      obj.organizationId,
      obj.token,
    )

    return response
  },
)

export const geofenceUpdated = createAsyncThunk(
  'geofences/geofenceUpdated',

  // The payload creator receives the partial `{title, content, user}` object
  async (obj: secureGeofence) => {
    // We send the initial data to the fake API server
    const response = await updateGeofence(
      obj.geofence,
      obj.token,
      obj.organizationId,
    )
    // The response includes the complete post object, including unique ID
    return obj.geofence // need to reutn the vehcile to add to the redux  store
  },
)

const geofenceSlice = createSlice({
  name: 'geofences',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchGeofences.pending, (state, action: PayloadAction) => {
        state.status = 'loading'
      })
      .addCase(fetchGeofences.fulfilled, (state, action) => {
        state.geofences = state.geofences.concat(action.payload.result)
        state.status = 'succeeded'
      })
      .addCase(fetchGeofences.rejected, (state, action) => {
        state.status = 'failed'
        state.error = 'Geofences list could not be retrieved'
        // state.vehicles.error = action.error.message
        //
      })
      .addCase(geofenceUpdated.fulfilled, (state, action) => {
        const updatedGeofence: Geofence = action.payload

        const exitingGeofence = state.geofences.find(
          (dev) => dev.id === updatedGeofence.id,
        )
        if (exitingGeofence) {
          exitingGeofence.name = updatedGeofence.name
        }
        alert('Geofence updated sucessfully')
      })
      .addCase(geofenceUpdated.rejected, (state, action) => {
        state.status = 'failed'
        state.error = 'Geofence could not be updated'
        // state.vehicles.error = action.error.message
        //
      })
  },
})

export const selectAllGeofences = (state: {
  geofences: { geofences: Geofence[] }
}) => state.geofences.geofences

export default geofenceSlice.reducer
