import { useEffect, useRef } from 'react'

function TestPage() {
  const mapRef = useRef(null)

  useEffect(() => {
    const script = document.createElement('script')
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`
    script.async = true
    document.body.appendChild(script)
    script.onload = () => {
      if (mapRef.current) {
        new window.google.maps.Map(mapRef.current, {
          center: { lat: -34.397, lng: 150.644 },
          zoom: 8,
        })
      }
    }

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return <div ref={mapRef} style={{ height: '400px', width: '100%' }} />
}

export default TestPage
