import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'

import { Driver, secureCompany, secureDriver } from '../../api/types'

import { addNewDriver, fetchDrivers } from './driverSlice'
import type { RootState } from '../../app/store'

import { Col } from 'react-bootstrap'

import { useAppDispatch } from '../../app/hooks'

import '../../styles/generic.css'
import { useSelector } from 'react-redux'
import { Button } from '@mui/material'

export const AddDriver = () => {
  const [name, setName] = useState('')
  const [email, setemail] = useState('')

  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const token = useSelector(
    (state: RootState) => state.session.userdata.accesstoken,
  )
  const organizationId = useSelector(
    (state: RootState) => state.session.userdata.organizationId,
  )
  const dispatch = useAppDispatch()

  const onNameChanged = (e: {
    target: { value: React.SetStateAction<string> }
  }) => setName(e.target.value)

  const onEmailChanged = (e: {
    target: { value: React.SetStateAction<string> }
  }) => setemail(e.target.value)

  const [addRequestStatus, setAddRequestStatus] = useState('idle')

  //#TODO improve this
  const canSave = [name].every(Boolean) && addRequestStatus === 'idle'

  const onSaveUserClicked = async () => {
    let todaysDate = new Date()
    const newDriver: Partial<Driver> = {
      name: name,
      createdAt: todaysDate,
    }

    if (canSave) {
      try {
        const obj: secureDriver = {
          driver: newDriver,
          token: token,
          organizationId,
        }

        setAddRequestStatus('pending')
        await dispatch(addNewDriver(obj)).unwrap()
        setName('')
      } catch (err) {
        console.error('Failed to save the Driver: ', err)
      } finally {
        setShow(false)
        setAddRequestStatus('idle')
        const company: secureCompany = { token, organizationId }
        dispatch(fetchDrivers(company))
      }
    } else {
      alert('Please enter a Name')
    }
  }

  return (
    <>
      <span
        style={{ display: 'flex', justifyContent: 'flex-end', marginRight: 20 }}
      >
        <Button
          variant="contained"
          onClick={handleShow}
          sx={{ alignSelf: 'flex-end' }}
        >
          + Add Driver
        </Button>
      </span>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Driver info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <Col sm={1}>
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                value={name}
                onChange={onNameChanged}
                style={{ minHeight: 37 }}
              />
              {/* <label htmlFor="name">email</label>
              <input
                type="text"
                id="name"
                name="name"
                value={email}
                onChange={onEmailChanged}
                style={{ minHeight: 37 }}
              /> */}

              <Button
                variant="contained"
                onClick={onSaveUserClicked}
                style={{ marginTop: 15 }}
              >
                Save
              </Button>
            </Col>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}
