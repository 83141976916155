import React, { useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
type HoverDropMenuProps = {
  items: string[]
  label: string
}
const HoverDropMenu = (props: HoverDropMenuProps) => {
  const [open, setOpen] = useState(false)
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null)

  return (
    <span
      style={{
        position: 'relative',
        display: 'inline-block',
        color: 'black',
      }}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => {
        setOpen(false)
        setHoveredIndex(null)
      }}
    >
      <label style={{ cursor: 'pointer' }}>{props.label}</label>
      <ExpandMoreIcon
        sx={{
          // marginLeft: theme.spacing(1 / 4),
          marginLeft: '4',
          width: 16,
          height: 16,
          // transform: openedPopoverId === id ? 'rotate(180deg)' : 'none',
          // color: linkColor,
        }}
      />
      {open && (
        <div
          style={{
            position: 'absolute',
            top: '100%', // Position below the label
            left: 0,
            backgroundColor: '#f9f9f9',
            minWidth: '160px',
            boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
            zIndex: 1,
            borderRadius: '4px',
          }}
        >
          {props.items.map((item, index) => (
            <p
              key={index}
              style={{
                margin: 0,
                padding: '12px 16px',
                cursor: 'pointer',
                backgroundColor:
                  hoveredIndex === index ? '#f1f1f1' : 'transparent',
                transition: 'background-color 0.3s ease',
              }}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              {item}
            </p>
          ))}
        </div>
      )}
    </span>
  )
}

export default HoverDropMenu
