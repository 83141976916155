import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'

import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'

import FormLabel from '@mui/material/FormLabel'
import NumbersIcon from '@mui/icons-material/Numbers'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { confirmUser, createUser } from './cogAccount'

import { useState } from 'react'

//temporary
import Pool from '../../api/cognito/UserPool'
import { addOrganization } from '../../api/api_functions'
import { Organization, User, UserSettingsBase } from '../../api/types'
import { useSelector } from 'react-redux'
import { RootState } from '../../app/store'
import PasswordValidatorComponent from '../../components/Passwordvalidator'
import { useNavigate } from 'react-router-dom'

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme()

interface orgSignUp {
  organization: Organization
  user: User
  userSettings: UserSettingsBase
}

export default function SignUp() {
  const [userEmail, setUserEmail] = useState<string>('')
  const [userPhoneNumber, setUserPhone] = useState<string>('')
  const [userName, setUserName] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  const [orgName, setOrganizationName] = useState<string>('')
  const [orgEmail, setOrgEmail] = useState<string>('')
  const [orgPhoneNumber, setOrgPhone] = useState<string>('')
  const [activeStep, setActiveStep] = useState<number>(0)

  const [orgTimezone, setOrgTimezone] = useState<string>('')
  const [orgAddress, setAddress] = useState<string>('')

  const [affiliation, setValue] = React.useState('create')
  const sessionToken = useSelector(
    (state: RootState) => state.session.userdata.accesstoken,
  )

  const [isFormValid, setIsFormValid] = React.useState(false)
  let navigate = useNavigate()
  // Function to validate the form
  const validateForm = () => {
    if (affiliation === 'create') {
      setIsFormValid(
        userName.trim() !== '' &&
          userPhoneNumber.trim() !== '' &&
          orgName.trim() !== '' &&
          orgTimezone.trim() !== '' &&
          orgPhoneNumber.trim() !== '' &&
          orgAddress.trim() !== '' &&
          orgEmail.trim() !== '',
      )
    } else if (affiliation === 'join') {
      console.log('todo')
    }
  }

  // Call validateForm whenever form fields change
  React.useEffect(() => {
    validateForm()
  }, [
    userName,
    userPhoneNumber,
    orgName,
    orgTimezone,
    orgPhoneNumber,
    orgAddress,
    orgEmail,

    affiliation,
  ])

  const handleOrganizationChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setValue((event.target as HTMLInputElement).value)
  }

  const SignUpUserHandleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    createUser(userEmail, password, Pool)
      .then((user) => {
        // Handle success
        console.log('User created:', user)
        setActiveStep(activeStep + 1) // Move to next step
      })
      .catch((error) => {
        // Handle error
        console.error('Signup failed:', error)
        if (error.code === 'UsernameExistsException') {
          alert('User already exists, please sign in')
        } else
          alert(
            'User could not be created please get in touch with us for support',
          )
      })
  }

  const CreateOrganizationHandleSubmit = (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault()

    const organization: Organization = {
      name: orgName,
      timezone: orgTimezone,
      address: orgAddress,
      phoneNumber: orgPhoneNumber,
      email: orgEmail,
    }

    const user: User = {
      name: userName,
      email: userEmail,
      phoneNumber: userPhoneNumber,
    }

    const orgInfo: orgSignUp = {
      organization,
      user,
      userSettings: { timezone: orgTimezone },
    }

    addOrganization('organization', orgInfo, sessionToken)
      .then((response) => {
        // Handle success
        console.log('Organization created:', response)
        setActiveStep(activeStep + 1) // Move to next step
        alert('Organization created successfully')
      })
      .catch((error: any) => {
        // Handle error
        console.error('Organization creation failed:', error)

        // Extract the error message
        const errorMessage = error.data
          ? error.data
          : 'An unknown error occurred'

        // Display a user-friendly alert
        alert(`Organization could not be created: ${errorMessage}`)
      })
  }

  const confirmUserCall = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    const code = String(data.get('code'))

    confirmUser(userEmail, code, Pool)
      .then((cognitoUser) => {
        // Handle successful confirmation
        console.log('User confirmed:', cognitoUser)
        setActiveStep(activeStep + 1) // Move to next step
      })
      .catch((error) => {
        // Handle confirmation error
        console.error('Confirmation failed:', error)
        alert('User could not be confirmed, please try again')
      })
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        {activeStep === 0 && ( //user sign up
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              maxWidth: '70%',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              1
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Please enter an email to sign-up
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={SignUpUserHandleSubmit}
              sx={{ mt: 3 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    onChange={(e) => {
                      setUserEmail(e.target.value)
                    }}
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    onChange={(e) => {
                      setPassword(e.target.value)
                    }}
                    autoComplete="password"
                    name="password"
                    required
                    fullWidth
                    id="password"
                    label="password"
                    autoFocus
                  />
                  <PasswordValidatorComponent password={password} />
                </Grid>

                <Grid item xs={12}></Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign Up
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link href="/login" variant="body2">
                    Already have an account? Sign in
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        )}

        {activeStep === 1 && ( //confirm user with code
          <Box component="form" noValidate onSubmit={confirmUserCall}>
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <NumbersIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Please enter the code sent to your email
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  style={{}}
                  autoComplete="code"
                  name="code"
                  id="code"
                  label="Code"
                  autoFocus
                />
              </Grid>
            </Grid>
            <Button type="submit" variant="contained" style={{ marginTop: 10 }}>
              Confirm
            </Button>
          </Box>
        )}
        {activeStep === 2 && ( //organization details
          <>
            <Box
              sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <CorporateFareIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Organization Affiliation
              </Typography>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={affiliation}
                onChange={handleOrganizationChange}
                sx={{ mt: 2 }}
              >
                <FormControlLabel
                  value="create"
                  control={<Radio />}
                  label="Create New Organization"
                />
                <FormControlLabel
                  value="join"
                  control={<Radio />}
                  label="Join Existing Organization"
                />
              </RadioGroup>

              <Box
                component="form"
                onSubmit={CreateOrganizationHandleSubmit}
                sx={{ mt: 3 }}
              >
                {affiliation === 'create' && (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography component="h1" variant="h6">
                        Please enter your personal details
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        autoComplete="Name"
                        name="Name"
                        id="Name"
                        label="Name"
                        autoFocus
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        autoComplete="Phone Number"
                        name="Phone Number"
                        id="Phone Number"
                        label="Phone Number"
                        value={userPhoneNumber}
                        onChange={(e) => setUserPhone(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography component="h1" variant="h6">
                        Please enter your organization details
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        autoComplete="Organization Name"
                        name="Organization Name"
                        id="Organization Name"
                        label="Organization Name"
                        value={orgName}
                        onChange={(e) => setOrganizationName(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        autoComplete="Time Zone"
                        name="timezone"
                        id="timezone"
                        label="Time Zone"
                        value={orgTimezone}
                        onChange={(e) => setOrgTimezone(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        autoComplete="Phone Number"
                        name="Phone Number"
                        id="phone"
                        label="Organization Phone Number"
                        value={orgPhoneNumber}
                        onChange={(e) => setOrgPhone(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        autoComplete="Organization Address"
                        name="orgaddress"
                        id="orgaddress"
                        label="Organization Address"
                        value={orgAddress}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        autoComplete="Organization Email"
                        name="orgEmail"
                        id="orgEmail"
                        label="Organization Email"
                        value={orgEmail}
                        onChange={(e) => setOrgEmail(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                )}
                {affiliation === 'join' && (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        autoComplete="Invitation Code"
                        name="icode"
                        id="icode"
                        label="Invitation Code"
                        autoFocus
                      />
                    </Grid>
                  </Grid>
                )}
                <Button
                  type="submit"
                  variant="contained"
                  disabled={!isFormValid}
                  sx={{ mt: 3, mb: 2 }}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </>
        )}
        {activeStep === 3 && (
          <Box component="form" noValidate>
            <Typography>
              {' '}
              Your organization has been created succesfully
            </Typography>
            <Button
              onClick={() => {
                navigate('/login', { replace: true })
              }}
            >
              Done
            </Button>
          </Box>
        )}
      </Container>
    </ThemeProvider>
  )
}
