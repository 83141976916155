import axios from 'axios'

import { Dict } from './types'
const API_URL = process.env.REACT_APP_API_URL!
const WS_URL = process.env.REACT_APP_WS_URL!

const f = (r: any): any => JSON.parse(r)

export const getDeviceData = async (
  deviceId: string,
  organizationId: string,
  sessionToken: string,
  startDate?: Date,
  endDate?: Date,
  retrieveDeviceData?: boolean,
  retrieveGeolocationData?: boolean | null,
  limit?: number,
  ascendingOrder?: boolean,
): Promise<any[]> => {
  const response = await axios.get<any>(`${API_URL}/deviceData/${deviceId}`, {
    params: {
      startDate,
      endDate,
      organizationId,
      retrieveDeviceData,
      retrieveGeolocationData,
      limit,
      ascendingOrder,
    },

    transformResponse: f,
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer ' + sessionToken,
    },
  })

  //
  return response.data
}

export const getHistoricDeviceData = async (
  organizationId: string,
  deviceId: string,
  sessionToken: string,
  startDate?: string,
  endDate?: string,
  ascendingOrder?: boolean,
): Promise<Dict> => {
  const response = await axios.get<any>(
    `${WS_URL}/historicDeviceData/${deviceId}`,
    {
      params: {
        deviceId,
        startDate,
        endDate,
        organizationId,
        ascendingOrder,
      },

      transformResponse: f,
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + sessionToken,
      },
    },
  )

  //
  return response.data
}
