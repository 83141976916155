import {
  InviteRole,
  RdxStateSession,
  secureUserCompany,
  UsersInfo,
} from '../../api/types'
import { useAppDispatch } from '../../app/hooks'
import { RootState } from '../../app/store'
import '../../styles/Login.css'
import { fetchUserSettings } from '../usersSettings/usersettingsSlice'
import { Copyright } from './Common'
import './SessionLogin.css'
import { reasons } from './cogAccount'
import { authenticate, getSession } from './cogAccount'
import { setCredentials } from './sessionSlice'

import { Grid, TextField } from '@mui/material'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
import Link from '@mui/material/Link'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import {
  CognitoUser,
  CognitoUserSession,
  IAuthenticationCallback,
} from 'amazon-cognito-identity-js'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Navigate } from 'react-router-dom'
import PasswordValidatorComponent from '../../components/Passwordvalidator'
import LeftContainer from './LeftContainer'
const theme = createTheme()

export type SessionLoginProps = { compactView: boolean }

const SessionLogin = (props: SessionLoginProps) => {
  const navigate = useNavigate()

  let [searchParams] = useSearchParams()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [organization, setOrganization] = useState('')
  const [role, setRole] = useState<InviteRole[]>([])
  const [changePass, setChangePass] = useState(false)
  const [rawdata, setrawdata] = useState<any>()
  const [rolePicker, setRolePicker] = useState(false)
  const [enablesave] = useState(false)
  const [newPassword] = useState('')
  const [duplicate, setDuplicate] = useState('')
  const [userAttributes, setUserAttributes] = useState()
  const [coguser, setCogUser] = useState<CognitoUser>()
  const [errMsg, setErrMsg] = useState('')
  const [tempUsersData, settempUD] = useState<UsersInfo[]>([])

  const defaultTheme = createTheme() //TODO read  from redux
  const dispatch = useAppDispatch()
  const sessionStatus = useSelector((state: RootState) => state.session.status)
  //

  let params = '?'
  searchParams.forEach((value, key) => {
    params += key + '=' + value + '&'
  })

  const handleEmailBlur = () => {
    // Remove trailing spaces
    const trimmedEmail = email.trim()
    setEmail(trimmedEmail)
  }

  const fetchSession = async () => {
    getSession()
      .then((data: any) => {
        //TODO add types from api

        const session: CognitoUserSession = data['session']
        const idToken = session.getIdToken().decodePayload()
        let userdata: UsersInfo[] = JSON.parse(idToken['users'])

        //this is coming from  getsession resolve review
        if (!Array.isArray(userdata)) {
          userdata = [userdata] // If is not an array convert it into an array with one element
        }

        // validating multiOrganization and Multirole
        if (userdata.length > 1) {
          settempUD(userdata)
          setRolePicker(true)
        }
        //leave it like this
        else {
          settempUD(userdata)
          if (userdata[0].roles.length > 1) setRolePicker(true)
          else {
            console.log('single Organization and role')
            setOrganization(userdata[0].organizationId)
            setRole(userdata[0].roles)

            localStorage.setItem('organizationId', organization)
            localStorage.setItem('role', JSON.stringify(role))

            try {
              console.log('heredata', data)
              proceed(data)
            } catch (error) {
              console.error('Error in proceed function:', error)
            }
          }
        }
        setrawdata(data)
      })
      .catch((e: any) => {
        alert('session could not be fetched')
        console.log(e)
      })
  }
  const login = (e: any) => {
    e.preventDefault()

    authenticate(email, password)
      .then((data: any) => {
        console.log(data)
        fetchSession()
      })
      .catch((reason: reasons) => {
        setErrMsg(reason.error)

        if (reason.error === 'changepass') {
          setChangePass(true)
          setCogUser(reason.user)

          setUserAttributes(reason.userAtt)
        } else {
          alert(reason)
        }
      })
  }
  const passworChallengeCallback: IAuthenticationCallback = {
    onSuccess: (
      session: CognitoUserSession,
      userConfirmationNecessary?: boolean,
    ) => {
      alert('Password changed Sucessfully')
      setChangePass(false)
    },
    onFailure: (err: any) => {
      alert('there was an error' + err)
    },
  }

  const handleSave = () => {
    coguser?.completeNewPasswordChallenge(
      newPassword,
      userAttributes,
      passworChallengeCallback,
    )
  }

  const selectRole = (e: any) => {
    e.preventDefault()
    setRole(e.target.value)
  }

  const selectOrganization = (e: any) => {
    e.preventDefault()
    setOrganization(e.target.value)
  }

  const proceed = (data: any) => {
    const session: CognitoUserSession = data['session']
    const idToken = session.getIdToken().decodePayload()

    const usersinfo: UsersInfo = JSON.parse(idToken['users'])
    console.log(usersinfo)
    const sessionUserdata: RdxStateSession = {
      username: data['user']['username'],
      accesstoken: session.getAccessToken().getJwtToken(),
      userinfo: usersinfo,
      idtoken: idToken,
      profile: data['profile'],
      organizationId:
        organization === '' ? usersinfo.organizationId : organization,

      role: usersinfo.roles,
    }

    dispatch(setCredentials(sessionUserdata))
    const secUser: secureUserCompany = {
      token: sessionUserdata.accesstoken,
      organizationId:
        organization === '' ? usersinfo.organizationId : organization,
      userId: usersinfo.userId,
    }

    dispatch(fetchUserSettings(secUser))
    // *********testing*************************************TODO WIP
    navigate('/tracking')
  }

  /**----------------------------------------------------------*/
  /** COMPONENT RETURN HERE                                    */
  /**----------------------------------------------------------*/
  return (
    <ThemeProvider theme={defaultTheme}>
      {!props.compactView && <LeftContainer />}

      {!changePass ? (
        <Container component="main" maxWidth="xs">
          {rolePicker ? (
            <Box
              sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',

                backgroundColor: 'white', //added by Yeyi
              }}
            >
              <label>Organization</label>
              {tempUsersData.length > 1 && (
                <select onChange={selectOrganization}>
                  {tempUsersData.map((item: UsersInfo, index: number) => {
                    return (
                      <option key={index} value={item.organizationId}>
                        {item.organizationId}
                      </option>
                    )
                  })}
                  <option> </option>
                </select>
              )}

              {tempUsersData[0].roles.length > 1 && (
                <>
                  <label>Role</label>
                  <select onChange={selectRole}>
                    {tempUsersData[0].roles.map(
                      (item: InviteRole, index: number) => {
                        return (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        )
                      },
                    )}
                    <option> </option>
                  </select>
                </>
              )}

              <button onClick={() => proceed(rawdata)}>GO</button>
            </Box>
          ) : sessionStatus === 'succeeded' ? (
            <Navigate to="/tracking" replace />
          ) : (
            <Box
              sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <form
                onSubmit={login}
                style={{ display: 'flex', height: 'fit-content' }}
              >
                <label>{errMsg}</label>

                <div>
                  <img
                    sizes="20"
                    src="Company_Logo2.png"
                    alt="Vermmis Company"
                    style={{
                      width: '80px',
                      height: '50px',
                      marginBottom: '16px',
                    }}
                  ></img>
                  <h2>Sign in to your account</h2>

                  <div>
                    <TextField
                      placeholder="jane@example.com"
                      value={email}
                      onChange={(event: {
                        target: { value: React.SetStateAction<string> }
                      }) => setEmail(event.target.value)}
                      onBlur={handleEmailBlur}
                      fullWidth
                      margin="normal"
                    >
                      Email
                    </TextField>
                  </div>

                  <TextField
                    onChange={(e: any) => setPassword(e.target.value)}
                    value={password}
                    type="password"
                    fullWidth
                    margin="normal"
                  />
                  <Grid item xs>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      onClick={login}
                      fullWidth
                    >
                      Login
                    </Button>
                  </Grid>
                  <Grid container justifyContent="flex-end">
                    <Grid item xs>
                      <Link href="/forgotpassword" variant="body2">
                        Forgot password?
                      </Link>

                      <Grid item>
                        <Link href="/signup" variant="body2">
                          Don' have an account? Sign Up
                        </Link>
                      </Grid>
                    </Grid>
                    {props.compactView && (
                      <Grid item>
                        <Copyright sx={{ mt: 8, mb: 4 }} />
                      </Grid>
                    )}
                  </Grid>
                </div>
              </form>
            </Box>
          )}
        </Container>
      ) : (
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <h5>Please set a new password </h5>

              <TextField
                margin="normal"
                required
                fullWidth
                label="new password"
                value={newPassword}
                // type={passwordShown ? 'text' : 'password'}
                type="text"
              />

              <TextField
                type="text"
                margin="normal"
                required
                fullWidth
                label="confirm password"
                value={duplicate}
                onChange={(e) => {
                  setDuplicate(e.target.value)
                }}
              />

              <Button
                fullWidth
                variant="contained"
                disabled={!enablesave}
                className="btn btn-primary btn-block"
                onClick={handleSave}
              >
                save
              </Button>
              <div></div>
            </Box>
            <Box>
              {/* TODO need improvmeents to be reusable */}
              <PasswordValidatorComponent password={newPassword} />
            </Box>
          </Container>
        </ThemeProvider>
      )}

      {/*-----------------------------------------------------*/}
      {/*SESSION LOGIN RIGHT CONTAINER ENDS HERE              */}
      {/*-----------------------------------------------------*/}
    </ThemeProvider>
  )
}

export default SessionLogin
