import { ClickAwayListener } from '@mui/material'
import React, { Fragment, useCallback, useRef, useState } from 'react'
import { HexColorPicker } from 'react-colorful'
import './vecfeat.css'

type PopProps = {
  color: string | undefined
  onChangecallback: (e: any) => void
}

export const PopoverPicker = (props: PopProps) => {
  const popover = useRef<HTMLDivElement>(null)
  const [isOpen, setisOpen] = useState(false)

  const close = useCallback(() => setisOpen(false), [])

  return (
    <span className="picker" onBlur={() => setisOpen(!isOpen)}>
      <span
        className="swatch"
        style={{ backgroundColor: props.color ? props.color : '#0000' }}
        onClick={() => setisOpen(!isOpen)}
        onBlur={() => setisOpen(!isOpen)}
      >
        <label style={{ color: props.color }}>OL</label>
      </span>

      {isOpen && (
        <Fragment>
          <ClickAwayListener
            onClickAway={() => {
              setisOpen(false)
            }}
          >
            <HexColorPicker
              color={props.color}
              onChange={props.onChangecallback}
            />
          </ClickAwayListener>
        </Fragment>
      )}
    </span>
  )
}
