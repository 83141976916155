import * as React from 'react'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'

import Tooltip from '@mui/material/Tooltip'

import Settings from '@mui/icons-material/Settings'
import Logout from '@mui/icons-material/Logout'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { userLogout } from '../../redux_features/session/cogAccount'
import { deauthenticate } from '../../redux_features/session/sessionSlice'
import { useAppDispatch } from '../../app/hooks'
import { RootState } from '../../app/store'
import { useSelector } from 'react-redux'

type prfmenprops = {
  status: boolean
}

export default function UserProfileMenu(props: prfmenprops) {
  const dispatch = useAppDispatch()
  let navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const [width, setWindowWidth] = useState(window.innerWidth)
  const [offset, setOffset] = useState(30)
  const session = useSelector((state: RootState) => state.session.userdata)
  const userSettings = useSelector(
    (state: RootState) => state.usersettings.usersettings,
  )

  useEffect(() => {
    window.addEventListener('resize', updateDimensions)

    return () => {
      window.removeEventListener('resize', updateDimensions)
    }
  }, [])

  useEffect(() => {
    if (props.status) setOffset(70)
  }, [props.status])

  const updateDimensions = () => {
    const width = window.innerWidth
    setWindowWidth(width)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const LogoutandRedirect = () => {
    console.log('here loing out')
    userLogout()
    dispatch(deauthenticate('test')) // TODO  good opractice to clean the whole redux store..!
  }
  const s1 = {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
  }

  const s2 = {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
  }
  // {`contenedor ${!inactive ? 'inactive' : ''}`}
  return (
    <div
      style={{
        zIndex: 1,
      }}
    >
      <Box sx={props.status ? s2 : s1}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}>
              {/* {session.username.charAt(0).toUpperCase()} */}
              {userSettings?.user?.name.charAt(0).toUpperCase()}
            </Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem>
          <Avatar /> {userSettings?.user?.name}
        </MenuItem>
        <MenuItem
          onMouseDown={() => {
            navigate('/account', { replace: true })
          }}
        >
          <Avatar /> Account
        </MenuItem>
        <Divider />
        {/* <MenuItem>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          Add another account
        </MenuItem> */}
        <MenuItem
          onMouseDown={() => {
            navigate('/settings/user', { replace: true })
          }}
        >
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem onMouseDown={LogoutandRedirect}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </div>
  )
}
