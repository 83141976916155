import React, { useEffect, useRef, useState } from 'react'
import './CustomSwipeableDrawer.css'

type CustomSwipeableDrawerProps = {
  content: any
}
export default function CustomSwipeableDrawer({
  content,
}: CustomSwipeableDrawerProps) {
  /*-------------------------------------------------------------------------- */
  /** CONSTANTS HERE                                                           */
  /*-------------------------------------------------------------------------- */
  const scrollableRef = useRef(null)
  const contentReachedTop = useRef(true)
  const contentReachedBottom = useRef(true)

  /*-------------------------------------------------------------------------- */
  /** STATE VARIABLES HERE                                                     */
  /*-------------------------------------------------------------------------- */
  const [containerHeight, setContainerHeight] = useState(35) // Initial height of the container
  const isDrawerNormalRef = useRef(false)
  const isDrawerMinimizedRef = useRef(false)
  const isDrawerMaximizedRef = useRef(false)
  const containerHeightRef = useRef(250)
  const touchStartYRef = useRef(null)
  const contentContainerTouchStartYRef = useRef(null)

  /*-------------------------------------------------------------------------- */
  /** HANDLERS HERE                                                            */
  /*-------------------------------------------------------------------------- */
  const handleScroll = (event: any) => {
    const node = scrollableRef.current
    if (!node) return

    const { scrollTop } = node

    // Check if at the Top
    const contentIsAtTop = scrollTop === 0 // Add a tolerance to handle floating point inaccuracies
    contentReachedTop.current = contentIsAtTop
  }

  /**------------------------------------------------------------------------------------*/
  /** REDUX SELECTORS HERE                                                               */
  /**------------------------------------------------------------------------------------*/

  /**------------------------------------------------------------------------------------*/
  /** USE EFFECTS HERE                                                                   */
  /**------------------------------------------------------------------------------------*/
  useEffect(() => {
    isDrawerNormalRef.current = true

    const handleTouchStart = (e: any) => {
      touchStartYRef.current = e.touches[0].clientY
    }

    const handleTouchMove = (e: any) => {
      if (touchStartYRef.current !== null) {
        const deltaY = touchStartYRef.current - e.touches[0].clientY
        setContainerHeight((prevHeight) => prevHeight + deltaY)
        containerHeightRef.current = containerHeight + deltaY
        touchStartYRef.current = e.touches[0].clientY

        const myDeltaY = window.innerHeight - e.touches[0].clientY

        if (deltaY < 0) {
          //console.log('GOING DOWM')
          if (myDeltaY > window.innerHeight * 0.5) {
            isDrawerNormalRef.current = true
            isDrawerMinimizedRef.current = false
            isDrawerMaximizedRef.current = false
          } else if (myDeltaY < window.innerHeight * 0.5) {
            isDrawerNormalRef.current = false
            isDrawerMinimizedRef.current = true
            isDrawerMaximizedRef.current = false
          } else if (myDeltaY > window.innerHeight * 0.5) {
            isDrawerNormalRef.current = false
            isDrawerMinimizedRef.current = false
            isDrawerMaximizedRef.current = true
          }
        } else if (deltaY > 0) {
          //console.log('GOING UP')
          if (myDeltaY < window.innerHeight * 0.1) {
            isDrawerNormalRef.current = false
            isDrawerMinimizedRef.current = true
            isDrawerMaximizedRef.current = false
          } else if (
            myDeltaY > window.innerHeight * 0.1 &&
            myDeltaY < window.innerHeight * 0.35
          ) {
            isDrawerNormalRef.current = true
            isDrawerMinimizedRef.current = false
            isDrawerMaximizedRef.current = false
          } else if (myDeltaY > window.innerHeight * 0.35) {
            isDrawerNormalRef.current = false
            isDrawerMinimizedRef.current = false
            isDrawerMaximizedRef.current = true
          }
        }
      }
    }

    const handleTouchEnd = () => {
      if (isDrawerNormalRef.current === true) {
        setContainerHeight(35)
      }
      if (isDrawerMinimizedRef.current === true) {
        setContainerHeight(10)
      }
      if (isDrawerMaximizedRef.current === true) {
        setContainerHeight(85)
      }
      touchStartYRef.current = null
    }

    const handleContentContainerTouchStart = (e: any) => {
      contentContainerTouchStartYRef.current = e.touches[0].clientY
    }

    const handleContentContainerTouchMove = (e: any) => {
      if (contentContainerTouchStartYRef.current !== null) {
        const deltaY =
          contentContainerTouchStartYRef.current - e.touches[0].clientY
        contentContainerTouchStartYRef.current = e.touches[0].clientY

        if (deltaY < 0) {
          //console.log('GOING DOWM')
          contentReachedBottom.current = false
          if (contentReachedTop.current) {
            e.preventDefault()
            if (isDrawerNormalRef.current) {
              isDrawerNormalRef.current = false
              isDrawerMinimizedRef.current = true
              isDrawerMaximizedRef.current = false
            }
          }
        } else if (deltaY > 0) {
          //console.log('GOING UP')
          contentReachedTop.current = false
        }
      }
    }

    const handleContentContainerTouchEnd = () => {
      if (isDrawerNormalRef.current === true) {
        setContainerHeight(35)
      }
      if (isDrawerMinimizedRef.current === true) {
        setContainerHeight(10)
      }
      if (isDrawerMaximizedRef.current === true) {
        setContainerHeight(85)
      }
      contentContainerTouchStartYRef.current = null
    }

    const container = document.getElementById('swipe-container')

    if (container) {
      container.addEventListener('touchstart', handleTouchStart)
      container.addEventListener('touchmove', handleTouchMove)
      container.addEventListener('touchend', handleTouchEnd)
    }

    const contentContainer = document.getElementById('content-container')

    if (contentContainer) {
      contentContainer.addEventListener(
        'touchmove',
        handleContentContainerTouchMove,
      )
      contentContainer.addEventListener(
        'touchstart',
        handleContentContainerTouchStart,
      )
      contentContainer.addEventListener(
        'touchend',
        handleContentContainerTouchEnd,
      )
    }

    return () => {
      if (container) {
        container.removeEventListener('touchstart', handleTouchStart)
        container.removeEventListener('touchmove', handleTouchMove)
        container.removeEventListener('touchend', handleTouchEnd)
      }

      if (contentContainer) {
        contentContainer.removeEventListener(
          'touchstart',
          handleContentContainerTouchMove,
        )
        contentContainer.removeEventListener(
          'touchmove',
          handleContentContainerTouchStart,
        )
        contentContainer.removeEventListener(
          'touchend',
          handleContentContainerTouchEnd,
        )
      }
    }
  }, [])

  return (
    <div
      style={{
        height: `${containerHeight}svh`,
        touchAction: 'none',
        transition: 'height ease 0.1s',
      }}
      className="CustomSwipeableDrawer--MainContainer"
    >
      <div
        id="swipe-container"
        className="CustomSwipeableDrawer--PullerContainer"
      >
        <span className="material-icons">horizontal_rule</span>
      </div>
      <div
        id="content-container"
        ref={scrollableRef}
        onScroll={handleScroll}
        className="CustomSwipeableDrawer--ContentContainer"
      >
        {content}
      </div>
    </div>
  )
}
