import { useEffect, useState } from 'react'
import { LastKnownData, VehicleControllerProps } from '../api/types'
import VehicleListItemDev from './VehicleListItem-dev'

import { useSelector } from 'react-redux'
import * as React from 'react'

import { Divider, List } from '@mui/material'
import { RootState } from '../app/store'
import useWindowSize from '../customHooks/useWindowSize'
import { Container } from 'react-bootstrap'

export const VehiclesControllers = (props: VehicleControllerProps) => {
  const [searchTerm, setSearchterm] = useState<string>('')

  const windowSize = useWindowSize()

  const liveGeolocations = useSelector(
    (state: RootState) => state.geolocations.geolocations,
  )

  const removeActiveClassFromSubMenu = () => {
    document.querySelectorAll('.sub-menu').forEach((el) => {
      el.classList.remove('active')
    })
  }
  useEffect(() => {
    let menuItems = document.querySelectorAll('.track-list-item')
    menuItems.forEach((el) => {
      el.addEventListener('click', (e) => {
        const next = el.nextElementSibling
        removeActiveClassFromSubMenu()
        menuItems.forEach((el) => el.classList.remove('active'))
        el.classList.toggle('active')

        if (next !== null) {
          next.classList.toggle('active')
        }
      })
    })
  }, [])

  const handleSearchChange = (val: any) => {
    //TODO
    const str: string = val.target.value
    setSearchterm(str)
  }
  console.log('liveGeolocations', liveGeolocations)

  //TODO irmpove this
  return (
    <Container
      style={{
        marginTop: 4,
        padding: 0,
      }}
    >
      <input
        type="text"
        placeholder="Search all drivers and vehicles"
        onChange={handleSearchChange}
        style={{
          marginLeft: 6,
          minHeight: 37,
          width: '95%',
          borderRadius: 5,
          borderWidth: 1,
        }}
      />
      {/* #TODO  Filters*/}

      <Divider />

      <List
        style={{
          padding: 0,
          width: '100%',
          maxHeight: windowSize.height - 200, // Adjust this value as needed
          overflow: 'auto',
          backgroundColor: '#FFF', //TODO theme
        }}
      >
        {liveGeolocations
          .filter((val: LastKnownData) => {
            if (searchTerm === '') {
              return val
            } else if (
              val.vehicleName
                ?.toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              val.driverName?.toLowerCase().includes(searchTerm.toLowerCase())
            ) {
              return val
            } else return null
          })
          .map((item: LastKnownData, index) => {
            return (
              <React.Fragment key={index}>
                <VehicleListItemDev
                  zoomin={() => {
                    props.onclickHandler(item)
                    // updateSelected(item.deviceId)
                  }}
                  clickHistory={props.historyClick}
                  clickInfo={props.infoClick}
                  geolocation={item}
                />
                <Divider style={{ height: 1, backgroundColor: '#000' }} />
              </React.Fragment>
            )
          })}
      </List>
    </Container>
  )
}

export default VehiclesControllers
