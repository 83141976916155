import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { getLatestGeoLocations } from '../../api/geoLocation'
import { LastKnownData, secureCompany } from '../../api/types'

let geolocations: LastKnownData[] = []

type instate = {
  geolocations: LastKnownData[]
  status: string
  error: null | string
}

let initialState: instate = {
  geolocations,
  status: 'idle',
  error: null,
}

export const fetchLiveGeolocations = createAsyncThunk(
  'geolocation/fetchliveGeolocations',
  async (obj: secureCompany) => {
    const result = await getLatestGeoLocations(obj.organizationId, obj.token)
    return result
  },
)

export const updateGeolocations = createAsyncThunk(
  'geolocation/updateFromWebSocket',
  async (data: LastKnownData[]) => {
    // Process and return the data
    return data
  },
)

const geoLocationsSlice = createSlice({
  name: 'geolocationSlice',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(
        fetchLiveGeolocations.pending,
        (state, action: PayloadAction) => {
          state.status = 'loading'
          state.error = null
        },
      )
      .addCase(fetchLiveGeolocations.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.geolocations = action.payload
        state.error = null
      })
      .addCase(fetchLiveGeolocations.rejected, (state, action) => {
        state.status = 'failed'
        state.error = 'livegeolocations could not be retrieved'
        //TODO  state.error = action.error.message
        // reset status of geolocations since it errored or leave it as it is?
      })

      .addCase(updateGeolocations.pending, (state, action: PayloadAction) => {
        //necessary to update the status
        state.status = 'loading'
        state.error = null
      })
      .addCase(updateGeolocations.fulfilled, (state, action) => {
        // Merge WebSocket data with existing state
        action.payload.forEach((newItem) => {
          const existingItemIndex = state.geolocations.findIndex(
            (item) => item.deviceId === newItem.deviceId,
          )
          if (existingItemIndex !== -1) {
            // Update existing item
            state.geolocations[existingItemIndex] = {
              ...state.geolocations[existingItemIndex],
              ...newItem,
            }
          } else {
            // Add new item
            state.geolocations.push(newItem)
          }
        })
        state.status = 'succeeded'
      })
  },
})

export default geoLocationsSlice.reducer

export const selectAllLiveGeolocations = (state: {
  geolocations: { geo: LastKnownData[] }
}) => state.geolocations
