import * as React from 'react'
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles'
import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'

import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'

import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'

import ListItemText from '@mui/material/ListItemText'

import { useNavigate } from 'react-router-dom'
import { Icon } from '@mui/material'
import logo from '../assets/logo/Company_Logo.png'
import useWindowSize from '../customHooks/useWindowSize'
import UserProfileMenu from './user/UserProfileMenu'
import UserNotifications from './user/UserNotifications'
import '../styles/MiniDrawer.css'

const drawerWidth = 240

type cdrawerprops = {
  inactive: boolean
  toggle: () => void
}

export const menuItems = [
  // {
  //   name: 'Dashboard',
  //   exact: true,
  //   to: '/dashboard',
  //   iconClassName: 'dashboard',
  // },
  {
    name: 'Tracking',
    exact: true,
    to: '/tracking',
    iconClassName: 'mode_of_travel',
  },

  {
    name: 'Vehicles',
    exact: true,
    to: `/settings/Vehicles`,
    iconClassName: 'local_shipping',
    subMenus: [],
  },
  {
    name: 'Drivers',
    exact: true,
    to: `/settings/drivers`,
    iconClassName: 'person',
    subMenus: [],
  },
  {
    name: 'Devices',
    exact: true,
    to: `/settings/devices`,
    iconClassName: 'sensors',
    subMenus: [],
  },
  {
    name: 'All Assets',
    exact: true,
    to: `/settings`,
    iconClassName: 'settings',
    subMenus: [],
  },
  // {
  //   name: 'Geofence',
  //   exact: true,
  //   to: `/geofences`,
  //   iconClassName: 'bi bi-geo-fill',
  //   subMenus: [],
  // },
]

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

export default function MiniDrawer(props: cdrawerprops) {
  /*-------------------------------------------------------------------------- */
  /** STATE VARIABLES HERE                                                     */
  /*-------------------------------------------------------------------------- */

  /*-------------------------------------------------------------------------- */
  /** HANDLERS HERE                                                            */
  /*-------------------------------------------------------------------------- */

  /*-------------------------------------------------------------------------- */
  /** CONSTANTS HERE                                                           */
  /*-------------------------------------------------------------------------- */
  const theme = useTheme()
  const windowsSize = useWindowSize()
  const navigate = useNavigate()
  const [open, setOpen] = React.useState(false)
  const [selected, setSelected] = React.useState<Number>()

  React.useEffect(() => {
    const isMobile = window.innerWidth <= 430
    setIsMobile(isMobile)
  }, [window.innerWidth])

  const [isMobile, setIsMobile] = React.useState<boolean>(false)

  return (
    <Box>
      <AppBar
        position="fixed"
        open={props.inactive}
        elevation={0}
        // color="secondary"
        {...(!isMobile
          ? {
              style: {
                background: '#FFFFFF',
                width: !props.inactive
                  ? windowsSize.width - 65
                  : windowsSize.width - 242,
              },
            }
          : {
              style: {
                background: '#f2f2f2',
                width: !props.inactive ? windowsSize.width : windowsSize.width,
              },
            })}
      >
        <Toolbar style={{ touchAction: 'none' }}>
          <div className="MiniDrawer--DrawerExpandButtonContainer">
            <IconButton
              // color="inherit"
              aria-label="open drawer"
              onClick={props.toggle}
              edge="start"
              sx={{
                marginRight: 15,
                ...(open && { display: 'none' }),
              }}
            >
              {!props.inactive ? (
                <Icon
                  color="primary"
                  sx={{
                    marginRight: 0,
                    width: 'fit-content',
                    height: 'fit-content',
                  }}
                >
                  {'chevron_right'}
                </Icon>
              ) : (
                <Icon color="primary" sx={{ marginRight: 0 }}>
                  {'chevron_left'}
                </Icon>
              )}
              {/* <MenuIcon /> */}
            </IconButton>

            <Typography variant="h6" noWrap component="div">
              {/* Mini variant drawer */}
            </Typography>
          </div>

          <div className="MiniDrawer--ProfileNotificationsLogoContainer">
            {isMobile && (
              <a href="https://vermmis.com">
                <img src={logo} width="102" height="63" alt="Logo" />
              </a>
            )}
            <div className="MiniDrawer--ProfileNotificationsContainer">
              <UserNotifications status={true} />
              <UserProfileMenu status={true} />
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <div className="MiniDrawer--DrawerContainer">
        <Drawer variant="permanent" open={props.inactive}>
          <DrawerHeader>
            <div
              style={{ position: 'absolute', left: props.inactive ? 50 : -16 }}
            >
              <a href="https://vermmis.com">
                <img src={logo} width="102" height="63" alt="Logo" />
              </a>
            </div>
          </DrawerHeader>
          <Divider />
          <List>
            {menuItems.map((item, index) => (
              <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  onSelect={() => {
                    setSelected(index)
                  }}
                  selected={index === selected}
                  sx={{
                    minHeight: 48,
                    justifyContent: props.inactive ? 'initial' : 'center',
                    px: 2.5,
                  }}
                  onClick={() => {
                    navigate(item.to)
                  }}
                >
                  <Icon color="primary" sx={{ marginRight: 2 }}>
                    {item.iconClassName}
                  </Icon>
                  <ListItemText
                    primaryTypographyProps={{ fontSize: '14px' }}
                    primary={item.name}
                    sx={{ opacity: props.inactive ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
        </Drawer>
        <DrawerHeader />
        {/* do not delete to keep top margin for now */}
      </div>
    </Box>
  )
}
