import { Status, Wrapper } from '@googlemaps/react-wrapper'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { RootState } from '../app/store'

import { MapProps } from '../api/types'
import { getSharedAll } from '../api/insecurereq'
import { styles } from './mapstyles'
import useWindowSize from '../customHooks/useWindowSize'
import { setMapOnAll } from './MapController'
import { GeoLocation } from '../api/types'

const render = (status: Status): ReactElement => {
  return <h1>{status}</h1>
}

const GenerateMakers = (geolocations: GeoLocation[]) => {
  const markers = geolocations.map((element, i: number) => {
    const mpoint: google.maps.LatLngLiteral = {
      lat: Number(element.latitude),
      lng: Number(element.longitude),
    }

    const svgMarker: google.maps.Symbol = {
      path: google.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
      fillColor: 'blue',
      fillOpacity: 0.6,
      strokeWeight: 0,
      rotation: element.heading + 180,
      scale: 6,
      anchor: new google.maps.Point(0, 0),
    }

    // const iconres: google.maps.Icon = {
    //   url:
    //     iconbaseurl +
    //     vehicles.find((vehic: Vehicle) => vehic?.device?.id === k)?.icon,
    //   scaledSize: new google.maps.Size(50, 50), // scaled size
    //   labelOrigin: new google.maps.Point(25, -5),
    //   // origin: new google.maps.Point(0, 0), // origin
    //   // anchor: new google.maps.Point(0, 0), // anchor
    // }

    const marker = new google.maps.Marker({
      position: mpoint,
      //map,
      icon: svgMarker,

      // icon: iconres,

      label: {
        text: geolocations[0].speed?.toString()!,
        fontSize: '22px',
      },
    })

    // const infowindow = new google.maps.InfoWindow({
    //   // content: element.timestamp?.toString(),
    //   content:
    //     '<div >' +
    //     'Driver: ' +
    //     vehicles.find((vehic: Vehicle) => vehic?.device?.id === k)?.driver
    //       ?.name +
    //     '</div >' +
    //     '<div >' +
    //     'Vehicle: ' +
    //     vehicles.find((vehic: Vehicle) => vehic?.device?.name === k)?.name +
    //     '</div >' +
    //     '<div >' +
    //     'Device: ' +
    //     element.deviceId +
    //     '</div >' +
    //     '<div >' +
    //     'Last seen: ' +
    //     element.timestamp?.toString() +
    //     '</div>' +
    //     '<div >' +
    //     'Speed: ' +
    //     element.speed +
    //     '</div >',
    // })
    // //developers.google.com/maps/documentation/javascript/events
    // https: marker.addListener('click', () => {
    //   if (props.markerClicked) props.markerClicked(k)
    // })

    // marker.addListener('mouseover', () => {
    //   infowindow.open({
    //     anchor: marker,
    //     //  map,
    //     shouldFocus: false,
    //   })
    // })
    // marker.addListener('mouseout', function () {
    //   infowindow.close()
    // })
    return marker
  })

  return markers
  //map
}

const SharedLocationMap = () => {
  const refmap = useRef<HTMLDivElement>(null)
  const [map, setMap] = useState<google.maps.Map>()
  const params = useParams()
  const [sharedGeolocations, setSharedGeolocations] = useState<GeoLocation[]>()
  const REACT_APP_GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY

  const windowsSize = useWindowSize()
  useEffect(() => {
    fetchSharedGeolocation()
  }, [])

  useEffect(() => {
    if (map && sharedGeolocations) {
      setMapOnAll(map, GenerateMakers(sharedGeolocations!))
    }
  }, [sharedGeolocations, map])

  const fetchSharedGeolocation = async () => {
    if (params.shareid) {
      const result = await getSharedAll<GeoLocation>(
        'sharedGeolocations',
        params.shareid,
      )

      setSharedGeolocations(result)
    }
  }

  let rmstyle = 'default'

  const mapProps: MapProps = {
    center: { lat: 26.25979, lng: -80.08094 }, // algorithm with all geolocations to center I would say mean point of max and min lat an long
    zoom: 8,
    height: '100%',
    width: '100%',

    cssstyle: 'map2',
    mapstyle: rmstyle,
    enable_controller: false,
  }

  useEffect(() => {
    if (refmap.current && !map) {
      setMap(
        new google.maps.Map(refmap.current, {
          center: mapProps.center,
          zoom: mapProps.zoom,
          fullscreenControl: false,
          styles: styles[mapProps.mapstyle],
          mapTypeControlOptions: {
            position: google.maps.ControlPosition.RIGHT_TOP,
          },
        }),
      )
    }
  }, [refmap.current, map])

  return (
    <div style={{}}>
      <Wrapper apiKey={REACT_APP_GOOGLE_API_KEY!} render={render}>
        {/* <MainMap {...mapProps}>map</MainMap> */}
        <div>
          <div
            id="map"
            style={{
              position: 'relative',
              height: `${windowsSize.height}px`,
              width: '100%',
              overflow: 'visible',
            }}
            ref={refmap}
          />
        </div>
      </Wrapper>
    </div>
  )
}

export default SharedLocationMap
