import { deleteuser, userUpdated, fetchusers, selectAllusers } from './userSlice'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import type { RootState } from '../../app/store'
import { useAppDispatch } from '../../app/hooks'

import { User, secureUser, secureCompany } from '../../api/types'

import { FiSearch } from 'react-icons/fi'

import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined'
import {
  Avatar,
  Button,
  CircularProgress,
  Container,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import useWindowSize from '../../customHooks/useWindowSize'
import { AddUser } from './AddUserModal'
function UserRow(item: User) {
  const dispatch = useAppDispatch()
  const [open, setOpen] = useState(null)
  const [onEditRow, setOnEditRow] = useState(false)
  const [name, setName] = useState(item.name)

  const sessionToken = useSelector((state: RootState) => state.session.userdata.accesstoken)
  const organizationId = useSelector((state: RootState) => state.session.userdata.organizationId)
  const role = useSelector((state: RootState) => state.session.userdata.role)
  const editRow = (e: any) => {
    e.preventDefault()
    setOnEditRow(!onEditRow)
  }

  const onNameChanged = (e: any) => {
    setName(e.target.value)
  }

  const handleOpenMenu = (event: any) => {
    setOpen(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setOpen(null)
  }
  const onUpdateUserClicked = async () => {
    //spread and update elements here
    let tempuser: User = { ...item }
    tempuser.name = name

    if (tempuser) {
      const obj: secureUser = {
        user: tempuser,
        token: sessionToken,
        organizationId,
      }

      try {
        await dispatch(userUpdated(obj))
      } catch (err) {
      } finally {
        setOnEditRow(false)
      }
    }
  }

  const onRemoveClicked = async () => {
    let tempuser: User = { ...item }

    const obj: secureUser = {
      user: tempuser,
      token: sessionToken,
      organizationId,
    }

    try {
      await dispatch(deleteuser(obj))
    } catch (err) {
    } finally {
    }
  }

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Avatar alt={item.name} sx={{ backgroundColor: '#dfe2e7' }} />
            <Typography noWrap>{item.name}</Typography>
          </Stack>
        </TableCell>
        <TableCell>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography noWrap>{item.email}</Typography>
          </Stack>
        </TableCell>
        <TableCell>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography noWrap>{item.status}</Typography>
          </Stack>
        </TableCell>
        <TableCell>
          <Stack direction="row" alignItems="center" spacing={2}>
            {/* <Typography noWrap>{item.createdAt?.toDateString()}</Typography> */}
            <Typography noWrap>{item.createdAt?.toString()}</Typography>
          </Stack>
        </TableCell>
        {!onEditRow && (
          <TableCell>
            <Tooltip title="Edit User">
              <Button
                sx={{ marginTop: '-5px' }}
                onClick={handleOpenMenu}
                disabled={role[0] == 'admin' ? false : true} //#TODO
              >
                <MoreVertOutlinedIcon />
              </Button>
            </Tooltip>
          </TableCell>
        )}
      </TableRow>
    </>
  )
}

function UsersList() {
  const dispatch = useAppDispatch()
  const usersStatus = useSelector((state: RootState) => state.users.status)
  const sessionToken = useSelector((state: RootState) => state.session.userdata.accesstoken)
  const organizationId = useSelector((state: RootState) => state.session.userdata.organizationId)
  const error = useSelector((state: RootState) => state.users.error)
  const [onEdituser, setOnEdituser] = useState(false)
  const [searchTerm, setSearchterm] = useState<string>('')
  const [nameSearch, setNameSearch] = useState<string>('')

  const users: User[] = useSelector(selectAllusers)

  useEffect(() => {
    if (usersStatus === 'idle') {
      //add code statuts in

      const secCompany: secureCompany = {
        token: sessionToken,
        organizationId: organizationId,
      }
      dispatch(fetchusers(secCompany))
    }
  }, [usersStatus, dispatch])

  const handleSearchuser = (val: any) => {
    // find out why there is not target after specify type
    const str: string = val.target.value

    setSearchterm(str)
  }

  let content

  if (usersStatus === 'loading') {
    //content = <Spinner text="Loading..." />
    content = <CircularProgress />
  } else if (usersStatus === 'succeeded') {
    content = users
      .filter((val: User) => {
        if (searchTerm === '') {
          return val
        } else if (val.name.toLowerCase().includes(searchTerm.toLowerCase())) {
          return val
        } else return null
      })
      .map((chofer: User, index: number) => {
        return <UserRow {...chofer} key={index} />
      })
  } else {
    content = <div>{error}</div>
  }

  return (
    <>
      <Paper
        sx={{
          height: useWindowSize().height - 10,
          width: '100%',
          overflow: 'scroll',
        }}
      >
        <Container
          sx={{
            minWidth: 800,
            maxHeight: useWindowSize().height,
            overflow: 'scroll',
          }}
        >
          {' '}
          <Stack direction={'row'} style={{ justifyContent: 'space-between' }}>
            <TextField
              size="small"
              placeholder="user Name"
              onChange={handleSearchuser}
              style={{ marginTop: '10px', marginLeft: '60px' }}
            ></TextField>
            <FiSearch
              style={{
                marginTop: '15px',
                marginLeft: '-50px',
                width: '30px',
                height: '30px',
                opacity: '50%',
              }}
            />
            <AddUser />
          </Stack>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table" style={{ verticalAlign: 'baseline' }}>
              <TableHead>
                <TableCell>User Name</TableCell>
                <TableCell>Contact Info </TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Date Added </TableCell>
                <TableCell>Edit</TableCell>
              </TableHead>

              <TableBody>{content}</TableBody>
            </Table>
          </TableContainer>
        </Container>
      </Paper>
    </>
  )
}

export default UsersList
