import { Fragment, ReactElement } from 'react'

import { useState, useEffect } from 'react'
import '../styles/tracking-styles.css'

import { Wrapper, Status } from '@googlemaps/react-wrapper'

import { MapProps, secureCompany, secureUserCompany } from '../api/types'

import { useSelector } from 'react-redux'
import { RootState } from '../app/store'
import { useAppDispatch } from '../app/hooks'
import { MainMap } from './MainMap'
import { fetchLiveGeolocations } from '../redux_features/geolocations/geolocationSlice'

import { userLogout } from '../redux_features/session/cogAccount'
import { deauthenticate } from '../redux_features/session/sessionSlice'

//must be global otherwise is reloaded everytime with the component and never would stop the function
let xIntervId: any

const render = (status: Status): ReactElement => {
  return <h1>{status}</h1>
}

const MainMapContainer = (props: { polling: boolean }) => {
  //---inital state of date range do not erase

  //---
  const REACT_APP_GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY

  const timeout = 10000
  const stoptimeout = 3600000 / timeout //avoid api refresh after 1 hour this needs to be done liken netflix
  let timercount = 0

  // const dispatch = useAppDispatch()

  const dispatch = useAppDispatch()
  const organizationId = useSelector(
    (state: RootState) => state.session.userdata.organizationId,
  )
  const accessToken = useSelector(
    (state: RootState) => state.session.userdata.accesstoken,
  )

  const tokenexpDate = useSelector(
    (state: RootState) => state.session.userdata.idtoken['exp'],
  )

  let zoom
  let centerp: google.maps.LatLngLiteral
  centerp = {
    lat: 26.25979,
    lng: -80.08094,
  }
  zoom = 3

  //this is to  show the map only when the data is loaded the first time

  useEffect(() => {
    console.log('first  call')
    getLiveData()

    //(' will run every timeout seconds!'
    if (props.polling) {
      console.log(' running on periodic timer', props.polling)
      xIntervId = setInterval(getLiveData, timeout)
      return () => clearInterval(xIntervId)
    } else {
      console.log(' running on Web Socket')
      return undefined
    }
  }, [props.polling])

  const getLiveData = async () => {
    console.log('api pollling')
    const secCompany: secureCompany = {
      token: accessToken,
      organizationId: organizationId,
    }
    //stopping automatically if user keeps pulling data because left window open, change this to a warning like netflix's are you still watching
    await dispatch(fetchLiveGeolocations(secCompany))
    if (timercount === stoptimeout) {
      clearInterval(xIntervId)
      xIntervId = null
    }
    check_iftoken_expired(tokenexpDate)
  }
  const LogoutandRedirect = () => {
    userLogout()
    dispatch(deauthenticate('test')) // TODO  good opractice to clean the whole redux store..!
  }

  const check_iftoken_expired = (tokenexpDate: number) => {
    const currentTimestamp = Math.floor(Date.now() / 1000)

    if (tokenexpDate < currentTimestamp) {
      console.log('tokenExpired')
      LogoutandRedirect()
    }
  }

  let rmstyle = ''
  //TODO review this usersettings
  // if (usersettings?.mapOverlay) rmstyle = usersettings.mapOverlay
  // else
  rmstyle = 'default'
  const mapProps: MapProps = {
    center: centerp,
    zoom: zoom,
    height: '90vh',
    width: '100%',
    //TODO if no data is coming could crash substitute by ? in the future
    cssstyle: 'map',
    mapstyle: rmstyle,
    // markerClicked: () => {}, //TODO remove
    enable_controller: true,
  }
  console.log('rendering MainMapContainer', REACT_APP_GOOGLE_API_KEY)
  return (
    <Fragment>
      {REACT_APP_GOOGLE_API_KEY && (
        <Wrapper
          apiKey={REACT_APP_GOOGLE_API_KEY}
          libraries={['drawing', 'places']}
          render={render}
        >
          {/* {firstload ? ( */}
          <MainMap {...mapProps}>map</MainMap>
          {/* ) : (
            <CircularProgress />
          )} */}
        </Wrapper>
      )}
    </Fragment>
  )
}
export default MainMapContainer
//AIzaSyBgElOLY6RBv6aMX57jlhTOC8AxVEFfDiQ&libraries=drawing,places,marker
