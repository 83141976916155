import { useState, useEffect } from 'react'

import * as React from 'react'

import GeofencesController from './geofences/GeofencesController'

import { Tab, Tabs } from 'react-bootstrap'

import { LastKnownData, MapProps } from './../api/types'
import { useSelector } from 'react-redux'
import { RootState } from './../app/store'

import { styles } from './mapstyles'

import { selectAllVehicles } from '../redux_features/vehicles/vehicleSlice'
// import VehiclesControllers from './VehiclesController'
import VehiclesControllersDev from './VehiclesController_dev'

import VehicleHistoryController from './VehicleHistoryController'

import MapLiveMarkerController from './MapLiveMarkerController'
import RightDrawer from './RightDrawer'
import { VehicleInfo } from './VehicleInfo'
import './MapController.css'
import CustomSwipeableDrawer from './CustomSwipeableDrawer/CustomSwipeableDrawer'
import useIsMobile from '../customHooks/useIsMobile'

//do not delete

export interface Theme {}
export interface MapControllerprops extends MapProps {
  map: google.maps.Map | undefined
}

export const setMapOnAll = (
  map: google.maps.Map | null,
  markers: google.maps.Marker[],
) => {
  for (let i = 0; i < markers.length; i++) {
    markers[i].setMap(map)
  }
}

export const calculateMapCenterAndZoom = (
  livegeolocations: LastKnownData[],
) => {
  const coordinates: { lat: number; lng: number }[] = []
  console.log('lenght=', coordinates.length)

  livegeolocations.forEach((element) => {
    const lat = element.latitude
    const lng = element.longitude
    coordinates.push({ lat, lng })
  })

  if (!Array.isArray(coordinates)) {
    // Return default center and zoom if no coordinates are provided
    return { center: { lat: 0, lng: 0 }, zoom: 10 }
  }
  if (coordinates.length > 0) {
    // Initialize min and max values for latitude and longitude
    let minLat = coordinates[0].lat
    let maxLat = coordinates[0].lat
    let minLng = coordinates[0].lng
    let maxLng = coordinates[0].lng

    // Find the min and max values for latitude and longitude
    coordinates.forEach((coord) => {
      minLat = Math.min(minLat, coord.lat)
      maxLat = Math.max(maxLat, coord.lat)
      minLng = Math.min(minLng, coord.lng)
      maxLng = Math.max(maxLng, coord.lng)
    })

    // Calculate the center latitude and longitude using the average values
    const centerLat = (maxLat + minLat) / 2
    const centerLng = (maxLng + minLng) / 2

    // Calculate the distance between the center and the farthest coordinate
    const latRadian = (maxLat - minLat) * (Math.PI / 180)
    const lngRadian = (maxLng - minLng) * (Math.PI / 180)
    const distanceLat = 111.32 * latRadian // 1 degree of latitude is approximately 111.32 kilometers
    const distanceLng =
      111.32 * Math.cos(centerLat * (Math.PI / 180)) * lngRadian
    const maxDistance = Math.max(distanceLat, distanceLng)

    // Calculate the zoom level based on the distance

    const zoomFactor = 0.2 // Adjust this value to control how close the zoom should be (0.1 to 1, where 1 is no zoom reduction)
    const adjustedMaxDistance = maxDistance * zoomFactor

    //const zoom = Math.floor(Math.log2(360 / maxDistance))
    const zoom = Math.floor(Math.log2(360 / adjustedMaxDistance))

    // Return the calculated center and zoom level

    return { center: { lat: centerLat, lng: centerLng }, zoom }
  } else return { center: { lat: 0, lng: 0 }, zoom: 10 }
}

function MapController(props: MapControllerprops) {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true)

  const [openRightDrawer, setopenRightDrawer] = useState(false)
  const [rightDrawerMode, setRightDrawerMode] = useState<
    'geofence' | 'history' | '' | 'liveinfo' | 'event'
  >('')

  const [vehicleId, setDeviceId] = React.useState('')
  const isMobile = useIsMobile()
  const vehicleStatus = useSelector((state: RootState) => state.vehicles.status)
  const geolocationStatus = useSelector(
    (state: RootState) => state.geolocations.status,
  )

  const vehicles = useSelector(selectAllVehicles)

  const livegeolocations = useSelector(
    (state: RootState) => state.geolocations.geolocations,
  )
  const livegeolocationsstatus = useSelector(
    (state: RootState) => state.geolocations.status,
  )

  const [selectedTab, setSelectedTab] = useState('Vehicles') // Set the default tab
  console.log('render map')

  useEffect(() => {
    if (props.map) changeMapStyle(props.map, props.mapstyle)
  }, [props.mapstyle])

  const changeMapStyle = (map: google.maps.Map, style: string) => {
    map?.setOptions({ styles: styles[style] })
  }

  useEffect(() => {
    //setMapZoom(props.center)
    if (livegeolocationsstatus === 'succeeded') fitall()
  }, [props.center])

  const HistoryClick = async (deviceid: string) => {
    setDeviceId(deviceid)
    setRightDrawerMode('history')
    setopenRightDrawer(true)
  }

  const InfoClick = (e: string) => {
    setDeviceId(e)

    setRightDrawerMode('liveinfo')
    setopenRightDrawer(true)
  }
  const CloseRightDrawer = () => {
    setopenRightDrawer(false)
  }

  //******set  this can be moved inside vehicle controller

  const handleCardClick = (item: LastKnownData) => {
    let center: google.maps.LatLngLiteral = {
      lat: item.latitude,
      lng: item.longitude,
    }
    setDeviceId(item.deviceId)
    console.log('center=', center)
    props.map?.setCenter(center)
  }

  const fitall = () => {
    //based on zoom
    const zoomcenter = calculateMapCenterAndZoom(livegeolocations)

    //Hide MapController
    setIsCollapsed(true)

    props.map?.setZoom(zoomcenter.zoom)
    props.map?.setCenter({
      lat: zoomcenter.center.lat,
      lng: zoomcenter.center.lng,
    })
  }

  /**--------------------------------------------------------------------------------*/
  /** MAIN VIEW RETURN HERE                                                          */
  /**--------------------------------------------------------------------------------*/
  return (
    <>
      {!isMobile ? (
        <div>
          <div
            style={{
              position: 'fixed',
              right: '10px',
              top: '250px',
              zIndex: 1,
            }}
          >
            <span
              onClick={fitall}
              className="material-symbols-outlined"
              style={{ fontSize: '40px', color: '#1976d2', cursor: 'pointer' }}
            >
              fit_screen
            </span>
          </div>
          <div>
            <Tabs
              activeKey={selectedTab}
              onSelect={(eventKey) => {
                if (eventKey) setSelectedTab(eventKey)
              }}
            >
              <Tab eventKey="Vehicles" title="Vehicles">
                <VehiclesControllersDev
                  onclickHandler={handleCardClick}
                  historyClick={HistoryClick}
                  infoClick={InfoClick}
                  selectedTab={selectedTab}
                />
              </Tab>
              {!isMobile && (
                <Tab eventKey="Geofences" title="Geofences">
                  {/* <GeofencesController
                    {...props}
                    map={props.map}
                    selectedTab={selectedTab}
                  /> */}
                </Tab>
              )}
            </Tabs>
            {openRightDrawer &&
              vehicleId !== '' &&
              rightDrawerMode === 'history' && (
                <VehicleHistoryController
                  handleCloseButton={CloseRightDrawer}
                  device={vehicleId}
                  map={props.map}
                />
              )}

            {openRightDrawer && rightDrawerMode === 'liveinfo' && (
              <VehicleInfo deviceid={vehicleId} close={CloseRightDrawer} />
            )}

            {props.map && <MapLiveMarkerController map={props.map} />}
          </div>
        </div>
      ) : (
        <>
          <div
            style={{
              position: 'fixed',
              right: '10px',
              top: '250px',
              zIndex: 1,
            }}
          >
            <span
              onClick={fitall}
              className="material-symbols-outlined"
              style={{ fontSize: '40px', color: '#1976d2', cursor: 'pointer' }}
            >
              fit_screen
            </span>
          </div>
          <CustomSwipeableDrawer
            content={
              <VehiclesControllersDev
                onclickHandler={handleCardClick}
                historyClick={HistoryClick}
                infoClick={InfoClick}
                selectedTab={selectedTab}
              />
            }
          />
          {openRightDrawer &&
            vehicleId !== '' &&
            rightDrawerMode === 'history' && (
              <RightDrawer
                close={() => {
                  setopenRightDrawer(false)
                  setRightDrawerMode('')
                }}
                anchor="right"
              >
                <VehicleHistoryController
                  handleCloseButton={CloseRightDrawer}
                  device={vehicleId}
                  map={props.map}
                />
              </RightDrawer>
            )}

          {openRightDrawer && rightDrawerMode === 'liveinfo' && (
            <VehicleInfo
              deviceid={vehicleId}
              close={() => {
                setopenRightDrawer(false)
                setRightDrawerMode('')
              }}
            />
          )}

          {props.map && geolocationStatus === 'succeeded' && (
            <MapLiveMarkerController map={props.map} />
          )}
        </>
      )}
    </>
  )
}

export default MapController
