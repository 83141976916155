import * as React from 'react'
import './TimeLineCard.css'
import { htlrowprops } from '../VehicleHistoryTimeline'

type TimeLineCardProps = {
  props: htlrowprops,
  cardType: any
}
export default function TimeLineCard(props: htlrowprops) {
  /**--------------------------------------------------------------------------*/
  /** STATE VARIABLES HERE                                                     */
  /**--------------------------------------------------------------------------*/
  const [isSelected, setIsSelected] = React.useState(true) //Para saber si se ha seleccionado o no este item del timeline
  const [currentDate, setCurrenDate] = React.useState('')

  /**--------------------------------------------------------------------------*/
  /** HANDLERS AND FUNCTIONS HERE                                              */
  /**--------------------------------------------------------------------------*/
  const toggleIsSelected = () => {
    setIsSelected((prev) => !prev)
    setCurrenDate(new Date(props.item.timestamp).toUTCString())
    console.log(new Date(props.item.timestamp).toUTCString())
    console.log(new Date(props.item.timestamp).getUTCMonth())
  }
  /** Returns speed formatted*/
  const formatSpeed = (speed: number | null) => {
    if (speed !== null) {
      if (speed === 0) {
        return 0
      } else if (Number.isInteger(speed)) {
        return `${speed}.0`
      } else {
        return speed.toString()
      }
    }
  }
  return (
    <div
      className="TimeLineCard--MainContainer"
      onClick={toggleIsSelected}
      onMouseEnter={() =>
        props.onMouseOver(props.item.latitude, props.item.longitude)
      }
      onMouseLeave={() =>
        props.onMouseOut(props.item.latitude, props.item.longitude)
      }
    >
      <div className="TimeLineCard-LeftContanier">
        <div
          {...(props.cardType === 'firstItem'
            ? { className: 'firstItem' }
            : props.cardType === 'intermediateItem'
            ? { className: 'intermediateItem' }
            : { className: 'lastItem' })}
        >
          <div
            {...(isSelected
              ? { className: 'selected' }
              : { className: 'notSelected' })}
          ></div>
        </div>
      </div>
      <div className="TimeLineCard-CenterContanier">
        <text style={{ fontFamily: 'Montserrat', fontSize: '16px' }}>
          {new Date(props.item.timestamp).toUTCString()}
        </text>
        <text style={{ fontFamily: 'Montserrat', fontSize: '14px' }}>
          {props.item.address === null
            ? 'No Address Received'
            : props.item.address}
        </text>
      </div>
      <div className="TimeLineCard-RightContanier">
        <text
          style={{
            fontFamily: 'Montserrat',
            fontSize: '12px',
            textAlign: 'right',
          }}
        >
          {formatSpeed(props.item.speed)} mph
        </text>
      </div>
    </div>
  )
}
