import React from 'react'
import { Container, Typography } from '@mui/material'

const Footer = () => {
  return (
    <footer
      style={{
        padding: '20px',
        backgroundColor: '#f8f9fa',
        marginTop: '20px',
        bottom: '0',
      }}
    >
      <Container maxWidth="lg" style={{ textAlign: 'center' }}>
        <Typography variant="body1" component="p">
          &copy; 2024 Vermmis. All rights reserved.
        </Typography>
      </Container>
    </footer>
  )
}

export default Footer
