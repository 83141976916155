import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'

const LeftContainer = (props: any) => {
  const { themeName } = useSelector((state: any) => state.selectedThemeReducer)
  const navigate = useNavigate()

  const handleVermmisLogoClick = () => {
    navigate('/')
  }
  return (
    <>
      {/*-----------------------------------------------------*/}
      {/*SESSION LOGIN LEFT  CONTAINER                        */}
      {/*-----------------------------------------------------*/}
      <div
        {...(themeName === 'light'
          ? { className: 'SessionLogin--LeftContanier' }
          : { className: 'SessionLogin--LeftContanierDark' })}
      >
        <div className="SessionLogin--LogoContainer">
          <img
            className="SessionLogin--Logo"
            src="Company_Logo.png"
            alt="Vermmis Logo"
            onClick={handleVermmisLogoClick}
            style={{ cursor: 'pointer' }}
          />
        </div>
        <div className="SessionLogin--TitleSubtitleContainer">
          <p
            {...(themeName === 'light'
              ? { className: 'SessionLogin--Title' }
              : { className: 'SessionLogin--TitleDark' })}
          >
            Fleet Manager
          </p>
          <p
            {...(themeName === 'light'
              ? { className: 'SessionLogin--Subtitle' }
              : { className: 'SessionLogin--SubtitleDark' })}
          >
            Effortless Fleet Management at Your Fingertips
          </p>
        </div>
      </div>
      {/*-----------------------------------------------------*/}
      {/*SESSION LOGIN LEFT CONTAINER ENDS HERE               */}
      {/*-----------------------------------------------------*/}
    </>
  )
}

export default LeftContainer
