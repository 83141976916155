import { Link } from 'react-router-dom'
import React from 'react'
export default function NotFound() {
  return (
    <div>
      <h1>Oops! You seem to be lost.</h1>
      <p>Here are some helpful links:</p>
      <div>
        <Link to="/">Home</Link>
      </div>
      <div>
        <Link to="/login">Login</Link>
      </div>

      <Link to="/contact">Contact</Link>
    </div>
  )
}
