import { ReactNode } from 'react'
import { Button, Drawer } from '@mui/material'

import useWindowSize from '../customHooks/useWindowSize'
type rdprops = {
  children: ReactNode

  anchor: 'bottom' | 'left' | 'right' | 'top'
  close?: () => void
}

const RightDrawer = (props: rdprops) => {
  const width = useWindowSize().width

  return (
    <Drawer
      anchor={width > 600 ? 'right' : 'bottom'}
      open={true}
      sx={{
        position: 'absolute',
        top: 250,
        width: 300,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: 250,
          boxSizing: 'border-box',
        },
      }}
      variant="persistent"
    >
      <>
        <div style={{ marginTop: 70, opacity: 0.6 }}>
          <button
            onClick={() => {
              props.close?.() //// IM
            }}
            style={{
              right: 0,
              width: 30,
              position: 'absolute',
              borderRadius: 15,
              border: 'none',
            }}
          >
            X
          </button>
        </div>
        <div style={{ marginTop: 10 }}>{props.children}</div>
      </>
    </Drawer>
  )
}

export default RightDrawer
