import React, { useState, useRef } from 'react'
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Menu,
  MenuItem,
  IconButton,
  Box,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'

import SessionLogin from '../../redux_features/session/SessionLogin'
import useClickAway from '../../customHooks/useClickAway'
import useIsMobile from '../../customHooks/useIsMobile'
import HoverDropMenu from './HoverDropMenu'

type HoverDropMenuProps = {
  items: string[]
  label: string
}

const items: HoverDropMenuProps[] = [
  { label: 'Products', items: ['Product 1', 'Product 2', 'Product 3'] },
  { label: 'Solutions', items: ['Solution 1', 'Solution 2', 'Solution 3'] },
  { label: 'About', items: ['Company Info', 'Team', 'Careers'] },
]

const NavBarHeader = ({ isScrolled }: any) => {
  const [anchorLogin, setAnchorLogin] = useState(null)
  const [anchorBurguer, setAnchorBurguer] = useState(null)
  const dropdownRef = useRef(null)
  const isMobile = useIsMobile()

  const handleClickLogin = (event: any) => {
    setAnchorLogin(event.currentTarget)
  }

  const handleClickBurguer = (event: any) => {
    setAnchorBurguer(event.currentTarget)
  }

  const handleCloseLogin = () => {
    setAnchorLogin(null)
  }
  const handleCloseBurguer = () => {
    setAnchorBurguer(null)
  }

  const handleCloseAll = () => {
    handleCloseBurguer()
    handleCloseLogin()
  }
  const handleShowMenu = () => {}
  useClickAway(dropdownRef, handleCloseAll)

  return (
    <AppBar
      position="fixed"
      sx={{ backgroundColor: isScrolled ? 'white' : 'transparent' }}
      // elevation={isScrolled ? 4 : 0}
      elevation={0}
    >
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <img
            src="/Company_Logo2.png"
            alt="Vermmis Company"
            style={{ height: '40px', marginRight: '10px' }}
          />
          Vermmis
        </Typography>
        <Box
          sx={{
            marginLeft: { sm: 2, md: 3 }, // Adjust margin for the button
            display: { xs: 'none', sm: 'flex' },
            gap: { xs: 1, sm: 2, md: 3 },
          }}
        >
          {items.map((item, index) => {
            return <HoverDropMenu items={item.items} label={item.label} />
          })}
        </Box>
        <Box marginLeft={4} marginRight={4}>
          <Button variant="contained" onClick={handleClickLogin}>
            Login
          </Button>
        </Box>

        {isMobile && (
          <IconButton
            style={{ backgroundColor: 'white', color: 'black' }}
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleClickBurguer}
          >
            <MenuIcon />
          </IconButton>
        )}
        <Menu
          anchorEl={anchorBurguer}
          open={Boolean(anchorBurguer)}
          onClose={handleCloseBurguer}
        >
          <MenuItem>Products</MenuItem>
          <MenuItem>Solutions</MenuItem>
          <MenuItem>About</MenuItem>
        </Menu>
        {Boolean(anchorLogin) && (
          <div
            ref={dropdownRef}
            style={{
              top: '100%',
              width: '300px',
              position: 'absolute',
              backgroundColor: 'white',
              right: isMobile ? 'unset' : '0', // Center for mobile, right for desktop

              // fix this styling
            }}
          >
            <SessionLogin compactView={true} />
          </div>
        )}
      </Toolbar>
    </AppBar>
  )
}

export default NavBarHeader
