import React, { useState, useEffect, useMemo } from 'react'
import '../styles/Login.css'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

type PasswordValidatorProps = {
  password: string
}

const PasswordValidatorComponent: React.FC<PasswordValidatorProps> = ({
  password,
}) => {
  const [conditionsArray, setConditionsArray] = useState<boolean[]>([
    false,
    false,
    false,
    false,
    false,
  ])

  const checkLength = useMemo(() => (str: string) => str.length >= 8, [])
  const hasLowerCase = useMemo(() => (str: string) => /[a-z]/.test(str), [])
  const hasUpperCase = useMemo(() => (str: string) => /[A-Z]/.test(str), [])
  const hasNumber = useMemo(() => (str: string) => /[0-9]/.test(str), [])
  const hasSpecialChar = useMemo(
    () => (str: string) => /[-+_!@#$%^&*.,?]/.test(str),
    [],
  )

  const checkAll = () => {
    setConditionsArray([
      checkLength(password),
      hasLowerCase(password),
      hasUpperCase(password),
      hasNumber(password),
      hasSpecialChar(password),
    ])
  }

  useEffect(() => {
    checkAll()
  }, [password])

  const passwordRequirements: string[] = [
    '8-character minimum length',
    'Contains at least 1 lowercase letter',
    'Contains at least 1 uppercase letter',
    'Contains at least 1 number',
    'Contains at least 1 special character (-+_!@#$%^&*.,?)',
  ]

  return (
    <div className="password-validator">
      {passwordRequirements.map((req, index) => (
        <div key={index} className="password-requirement">
          <label className="password-requirement-label">{req}</label>
          {conditionsArray[index] && <CheckCircleIcon className="check-icon" />}
        </div>
      ))}
    </div>
  )
}

export default PasswordValidatorComponent
