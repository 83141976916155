import React, { useState, useEffect } from 'react'
import { useAppDispatch } from '../app/hooks'
import { userLogout } from '../redux_features/session/cogAccount'
import { deauthenticate } from '../redux_features/session/sessionSlice'

const IdleTimeout: React.FC = () => {
  const idleThreshold = 3600 // 5 minutes in seconds
  const [idleTime, setIdleTime] = useState(idleThreshold) // Initial value in seconds
  const dispatch = useAppDispatch()

  useEffect(() => {
    let logoutTimeout: NodeJS.Timeout

    const resetIdleTimer = () => {
      clearTimeout(logoutTimeout)
      setIdleTime(idleThreshold)
      startIdleTimer()
    }

    const startIdleTimer = () => {
      logoutTimeout = setTimeout(logoutUser, idleThreshold * 1000)
    }

    const LogoutandRedirect = () => {
      userLogout()
      dispatch(deauthenticate('test'))
    }

    const logoutUser = () => {
      alert('Logging out due to inactivity.')
      LogoutandRedirect()
    }

    const handleInteraction = () => {
      resetIdleTimer()
    }

    window.addEventListener('mousemove', handleInteraction)
    window.addEventListener('keydown', handleInteraction)
    window.addEventListener('click', handleInteraction)

    startIdleTimer()

    return () => {
      clearTimeout(logoutTimeout)
      window.removeEventListener('mousemove', handleInteraction)
      window.removeEventListener('keydown', handleInteraction)
      window.removeEventListener('click', handleInteraction)
    }
  }, [])

  useEffect(() => {
    const timer = setInterval(() => {
      setIdleTime((prevTime) => Math.max(prevTime - 1, 0))
    }, 1000)

    return () => {
      clearInterval(timer)
    }
  }, [])

  return <></>
}

export default IdleTimeout
