import './styles/App.css'
import { ReactNode, useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import MainMapContainer from './components/MainMapContainerPage'
import TestPage from './components/TestPage'
import Unauthorized from './components/Unauthorized'

import RequireCogAuth from './redux_features/session/RequireCogAuth'
import SignUp from './redux_features/session/SignUp'
import OrganizationEntities from './components/OrganizationEntities'

import SessionLogin from './redux_features/session/SessionLogin'

import './components/CustomDrawer'

import Help from './components/Help'
import UserAccount from './components/user/UserAccountPage'

import { useSelector } from 'react-redux'
import { RootState } from './app/store'
import UserPreferences from './components/user/UserPreferences'

import MiniDrawer from './components/MiniDrawer'
import SecurityPage from './components/SecurityPage'
import PaymentsPage from './components/PaymentsPage'

import ForgotPassword from './components/ForgotPassword'
import { createContext } from 'react'

import DriversList from './redux_features/drivers/DriversList'
import DevicesList from './redux_features/devices/DevicesList'

import { VehiclesList } from './redux_features/vehicles/VehicleList'
import NotFound from './components/Notfound'

import SharedLocationMap from './components/SharedLocationMap'
import ShareLocationList from './components/ShareLocationList'

import IdleTimeout from './components/IdleTimeout'
import WSocket from './components/WSocket'

import UsersList from './redux_features/users/UserList'
import GeoAccessList from './components/geofences/GeoAccessList'

import BottomTabs from '../src/components/BottomTabs/BottomTabs'

import Landing from './components/LandingPage/Landing'

export const DrawerContext = createContext(false)

const App = () => {
  const sessionStatus = useSelector((state: RootState) => state.session.status)
  const [pollingState, setPollingState] = useState(false)

  const [drawerStatus, setInactive] = useState(false)

  const [width, setWindowWidth] = useState(window.innerWidth)

  const usersettings = useSelector(
    (state: RootState) => state.usersettings.usersettings,
  )

  const [theme, setTheme] = useState('light')

  useEffect(() => {}, [])
  useEffect(() => {
    document.body.className = theme
  }, [theme])

  useEffect(() => {
    window.addEventListener('resize', updateDimensions)

    return () => {
      window.removeEventListener('resize', updateDimensions)
    }
  }, [])

  const updateDimensions = () => {
    const width = window.innerWidth

    setWindowWidth(width)
  }

  const deactivateIt = () => {
    setInactive(!drawerStatus)
  }

  const SimpleLayout = ({ children }: { children: ReactNode }) => {
    return <div>{children}</div>
  }

  const setPolling = (value: boolean) => {
    console.log(' setting polling to ', value)
    setPollingState(value)
  }
  console.log('rendering App')
  return (
    <DrawerContext.Provider value={drawerStatus}>
      <div
        style={{
          position: 'fixed',
          width: width,
          transition: '0.5s',
          overflow: 'scroll',
          height: '100vh',
        }}
      >
        <BrowserRouter>
          <>
            {sessionStatus === 'succeeded' ? (
              <div>
                <MiniDrawer toggle={deactivateIt} inactive={drawerStatus} />
                <WSocket WSdisconnectionCallback={() => setPolling} />
                <BottomTabs />
              </div>
            ) : (
              <div></div>
            )}

            <IdleTimeout />
            <Routes>
              <Route
                path="/sharedlocation/:shareid"
                element={<SharedLocationMap />}
              />
              <Route path="/" element={<Landing />} />
              <Route path="/sharedlocations/" element={<ShareLocationList />} />
              <Route path="*" element={<NotFound />} />
              <Route
                path="/login"
                element={<SessionLogin compactView={true} />}
              />{' '}
              <Route path="/unauthorized" element={<Unauthorized />} />
              <Route path="/signup" element={<SignUp />} />
              <Route
                path="/forgotpassword"
                element={
                  <SimpleLayout>
                    <ForgotPassword />
                  </SimpleLayout>
                }
              />
              <Route
                element={
                  <RequireCogAuth
                    drawerStatus={drawerStatus}
                    roleRequired="admin"
                  />
                }
              >
                <Route path="/testpage" element={<TestPage />} />
                {/* <Route path="/dashboard" element={<DashboardPage />} /> */}
                <Route
                  path="/tracking"
                  element={<MainMapContainer polling={pollingState} />} //used if the websocket goes down
                />
                <Route
                  path="/GA"
                  element={<MainMapContainer polling={pollingState} />}
                />
                <Route path="/accesslist" element={<GeoAccessList />} />
                <Route
                  path="/settings/user"
                  element={<UserPreferences {...usersettings} />}
                />
                <Route path="/settings/vehicles" element={<VehiclesList />} />
                <Route path="/settings/drivers" element={<DriversList />} />
                <Route path="/settings/devices" element={<DevicesList />} />
                <Route path="/settings/users" element={<UsersList />} />

                {/* <Route path="/viewUser/:userId" element={<SingleUser />} /> */}
                <Route path="/settings" element={<OrganizationEntities />} />
                <Route path="/account" element={<UserAccount />} />
                <Route path="/account/security" element={<SecurityPage />} />
                <Route path="/account/payments" element={<PaymentsPage />} />
                <Route path="/help" element={<Help />} />
              </Route>
            </Routes>
            {/* </div> */}
          </>
        </BrowserRouter>

        {/* </React.Fragment> */}
      </div>
    </DrawerContext.Provider>
  )
}

export default App
