import { useState } from 'react'
import '../styles/VehicleListItem.css'

import { useSelector } from 'react-redux'
import { RootState } from '../app/store'

import { LastKnownData } from '../api/types'
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined'

import { ShareLocationSettings } from './ShareLocationSettings'

import NearMeIcon from '@mui/icons-material/NearMe'
import {
  Button,
  Icon,
  ListItemText,
  MenuItem,
  MenuList,
  Popover,
  Tooltip,
} from '@mui/material'

import { SvgIcon } from '@mui/material'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import VpnKeyIcon from '@mui/icons-material/VpnKey'
import PersonIcon from '@mui/icons-material/Person'
import React from 'react'

type VehicleItemPropsDev = {
  //TODO get rid of redundant data and pass the major info
  zoomin: (dev: string) => void
  clickHistory: (device: string) => void
  clickInfo: (device: string) => void
  geolocation: LastKnownData
}

type GMMItem = {
  icon: string
  label: string
  callback: () => void
}

type GMMArray = {
  menurArr: GMMItem[]
}

const GenericMoreMenu = (props: GMMArray) => {
  const [open, setOpen] = useState(null)
  const handleOpenMenu = (event: any) => {
    setOpen(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setOpen(null)
  }

  return (
    <>
      <Tooltip title="Options">
        <Button sx={{ marginTop: '-5px' }} onClick={handleOpenMenu}>
          <MoreVertOutlinedIcon />
        </Button>
      </Tooltip>
      {open && (
        <Popover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          PaperProps={{
            sx: {
              p: 1,
              width: 200,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
              },
            },
          }}
        >
          <MenuList>
            {props.menurArr.map((item, index) => {
              return (
                <MenuItem
                  key={index}
                  onClick={item.callback}
                  value={item.label}
                >
                  <ListItemText>{item.label}</ListItemText>
                  <Icon>{item.icon}</Icon>
                </MenuItem>
              )
            })}
          </MenuList>

          {/* <MenuItem sx={{ color: 'error.main' }}>View History</MenuItem> */}
        </Popover>
      )}
    </>
  )
}

const VehicleListItem = (props: VehicleItemPropsDev) => {
  const [openShareWindow, setOpenShareWindow] = useState(false)
  const [ignitionOn] = useState(true) //TODO dummy data this should come in device data

  const livegeolocations = useSelector(
    (state: RootState) => state.geolocations.geolocations,
  )

  const VehicleMenu: GMMItem[] = [
    {
      icon: 'info',
      label: 'vehicle info',
      callback: () => {
        props.clickInfo(props.geolocation.deviceId)

        // navigate('/tracking', { replace: true })
      },
    },

    {
      icon: 'history',
      label: 'tracking history',
      callback: () => {
        props.clickHistory(props.geolocation.deviceId)
      },
    },
    {
      icon: 'share',
      label: 'share',
      callback: () => {
        setOpenShareWindow(true)
      },
    },
  ]

  // TODO review this DO NOT ERASE

  //#TODO fix date format
  const timestampIso = livegeolocations.find((item, index) => {
    return item.deviceId === props.geolocation.deviceId
  })?.timestamp

  return (
    <div
      key={props.geolocation.deviceId}
      className="p-4 bg-white shadow rounded-lg flex space-x-4 items-center"
    >
      <div
        className="flex-grow"
        onClick={() => {
          props.zoomin(props.geolocation.deviceId)
        }}
      >
        <div className="flex items-center space-x-2">
          <SvgIcon
            component={NearMeIcon}
            className="w-4 h-4"
            //   style={{ color: props.vehicle?.pathColor }} //TODO get the color from the vehicle
          />
          <h3 className="text-lg font-semibold">
            {props.geolocation?.vehicleName}
          </h3>
          <span style={{ position: 'absolute', right: 0 }}>
            <GenericMoreMenu menurArr={VehicleMenu} />
          </span>
        </div>

        <div className="flex items-center space-x-2 text-sm text-gray-600">
          <PersonIcon className="w-4 h-4" />
          <span>{props.geolocation?.driverName}</span>
        </div>
        <div className="flex items-center space-x-2 text-sm text-gray-600">
          <LocationOnIcon className="w-4 h-4" />
          <span>
            {props.geolocation?.address
              ? props.geolocation?.address
              : 'No Address'}
          </span>
        </div>

        <div className="flex items-center space-x-2 text-xs text-gray-400 mt-1">
          <DirectionsCarIcon
            className={`w-4 h-4 ${
              props.geolocation.moving ? 'text-green-500' : 'text-gray-400'
            }`}
          />
          <span>{props.geolocation.moving ? 'In Motion' : 'Stopped'}</span>
        </div>
        <div className="flex items-center space-x-2 text-xs text-gray-400 mt-1">
          <VpnKeyIcon
            className={`w-4 h-4 ${
              ignitionOn ? 'text-green-500' : 'text-gray-400'
            }`}
          />
          <span>{ignitionOn ? 'Ignition On' : 'Ignition Off'}</span>
        </div>
        <div className="flex items-center space-x-2 text-xs text-gray-400 mt-1">
          <AccessTimeIcon className="w-4 h-4" />

          <span>{timestampIso}</span>
        </div>
      </div>

      <div className="text-gray-400">
        <ShareLocationSettings
          open={openShareWindow}
          handleClose={() => setOpenShareWindow(false)}
          vehicleId={props.geolocation.vehicleId}
        />
      </div>
    </div>
  )
}

export default VehicleListItem
