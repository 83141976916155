import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'

import { Driver } from '../../api/types'

import { secureCompany, secureDriver } from '../../api/types'
import { addItem, getAll, updateItem } from '../../api/api_functions'

let drivers: Partial<Driver>[] = []

let initialState = {
  drivers: drivers,
  status: 'idle',
  error: '',
}

export const fetchDrivers = createAsyncThunk(
  'drivers/fetchDrivers',

  async (obj: secureCompany) => {
    const response = await getAll<Driver>(
      'drivers',
      obj.organizationId,
      obj.token,
    )

    return response
  },
)

export const addNewDriver = createAsyncThunk(
  'drivers/addNewDriver',
  // The payload creator receives the partial `{title, content, user}` object
  async (obj: secureDriver) => {
    //'
    const response = await addItem(
      'drivers',
      obj.driver,
      obj.token,
      obj.organizationId,
    )
    // The response includes the complete post object, including unique ID
    return obj.driver // need to reutn the user to add to the redux  store
  },
)

export const driverUpdated = createAsyncThunk(
  'drivers/driverUpdated',

  // The payload creator receives the partial `{title, content, user}` object
  async (obj: secureDriver) => {
    // We send the initial data to the fake API server
    const response = await updateItem(
      'drivers',
      obj.driver,
      obj.token,
      obj.organizationId,
    )
    console.log(response)
    // The response includes the complete post object, including unique ID
    console.log(obj.driver.id)
    // The response includes the complete post object, including unique ID
    return obj.driver // need to reutn the vehcile to add to the redux  store
  },
)
//same udpate endpoint
export const deleteDriver = createAsyncThunk(
  'drivers/deleteDriver',
  // The payload creator receives the partial `{title, content, user}` object
  async (obj: secureDriver) => {
    //
    const response = await updateItem(
      'drivers',
      obj.driver,
      obj.token,
      obj.organizationId,
    )

    console.log(response)
    // The response includes the complete post object, including unique ID
    console.log(obj.driver.id)
    return obj.driver.id // need to reutn the user to add to the redux  store
  },
)

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchDrivers.pending, (state, action: PayloadAction) => {
        state.status = 'loading'
      })
      .addCase(fetchDrivers.fulfilled, (state, action) => {
        state.status = 'succeeded'

        // Add any fetched posts to the array

        state.drivers = state.drivers.concat(action.payload.result)
      })
      .addCase(fetchDrivers.rejected, (state, action) => {
        state.status = 'failed'
        state.error = 'Drivers List could not be retrieved'
        // state.vehicles.error = action.error.message
        //
      })
      .addCase(addNewDriver.fulfilled, (state, action) => {
        // We can directly add the new post object to our posts array ....always remember that inside create slice inmutability is respected due tu intrinsic use of immer

        state.drivers.push(action.payload)
        alert('Driver Created sucessfuly')
      })
      .addCase(addNewDriver.rejected, (state, action) => {
        state.status = 'failed'
      })

      .addCase(driverUpdated.fulfilled, (state, action) => {
        const updatedUser: Partial<Driver> = action.payload

        const existingUser = state.drivers.find(
          (user) => user.id === updatedUser.id,
        )
        if (existingUser) {
          existingUser.name = updatedUser.name
        }
        alert('Driver updated sucessfully')
      })
      .addCase(deleteDriver.fulfilled, (state, action) => {
        const deletedDriverId = action.payload
        state.drivers = state.drivers.filter(
          (driver) => driver.id !== deletedDriverId,
        )
        state.status = 'succeeded'
      })
  },
})
export const selectAllDrivers = (state: { drivers: { drivers: Driver[] } }) =>
  state.drivers.drivers

export default usersSlice.reducer
