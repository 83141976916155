import { Avatar, Box, Card, Grid, IconButton, Typography } from '@mui/material'
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import React from 'react'
import { useNavigate } from 'react-router-dom';

function UserAccount() {
  let navigate = useNavigate()
  return <div>
    <Grid container spacing={1}>
      <Grid >
    <Box onClick={()=>{navigate('/account/security', { replace: false })
          }}
            sx={{
              minWidth:200,
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            
          >
           
            <IconButton>
              <ShieldOutlinedIcon />
              </IconButton>
             
         
            <Typography> Security</Typography>
          
            </Box>
            </Grid>
            <Grid >
    <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            
          >
            <Card onMouseDown={() => {
            navigate('/account/payments', { replace: false })
          }}>
            <IconButton>
              <AttachMoneyIcon />
              </IconButton>
             
         
            <Typography> Payments</Typography>
            </Card>
            </Box>
            </Grid>
            </Grid>
  </div>
}

export default UserAccount
