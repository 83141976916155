import React, { useState } from 'react'
import { CognitoUser } from 'amazon-cognito-identity-js'
import Pool from '../api/cognito/UserPool'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@mui/material'
import useWindowSize from '../customHooks/useWindowSize'

const steps = ['Enter e-mail', 'Enter-verification code and new password']

// to test this just find the anonymous or Forgotpassword states in the react componets deve tools an change to 1 and 2 :)

const ForgotPassword = () => {
  const [activeStep, setStage] = useState(1) // 1 = email stage, 2 = code stage
  const [email, setEmail] = useState('')
  const [code, setCode] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  let navigate = useNavigate()

  const getUser = () => {
    return new CognitoUser({
      Username: email.toLowerCase(),
      Pool,
    })
  }

  const sendCode = (event: { preventDefault: () => void }) => {
    event.preventDefault()

    getUser().forgotPassword({
      onSuccess: (data) => {},
      onFailure: (err) => {
        console.error('onFailure:', err)
      },
      inputVerificationCode: (data) => {
        setStage(2)
      },
    })
  }

  const resetPassword = (event: { preventDefault: () => void }) => {
    event.preventDefault()

    if (password !== confirmPassword) {
      console.error('Passwords are not the same')
      return
    }

    getUser().confirmPassword(code, password, {
      onSuccess: (data) => {
        alert(' Your password has been updated sucesfully')
        navigate('/')
      },
      onFailure: (err) => {
        alert('onFailure:' + err)
      },
    })
  }

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <Stepper
        orientation={useWindowSize().width > 600 ? 'horizontal' : 'vertical'}
        activeStep={activeStep - 1}
        sx={{
          marginTop: 5,
          marginBottom: 5,
          maxWidth: useWindowSize().width * 0.5,
        }}
      >
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {}
          const labelProps: {
            optional?: React.ReactNode
          } = {}

          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
              <StepContent>
                {activeStep === 1 && (
                  <>
                    <Typography>
                      Please enter your email to send you a verification code
                    </Typography>
                    <TextField
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                      label="e-mail"
                      autoComplete="email"
                      size="small"
                      sx={{ margin: '0px' }}
                    />
                    <div>
                      <Button
                        type="submit"
                        variant="outlined"
                        size="small"
                        sx={{ margin: '8px' }}
                        onClick={sendCode}
                      >
                        Send verification code
                      </Button>
                    </div>
                  </>
                )}
                {/* Code sent stage */}
                {activeStep === 2 && (
                  <>
                    <Typography>
                      Please check your email inbox and enter the code you
                      received( check spam folder)
                    </Typography>

                    <TextField
                      value={code}
                      onChange={(event) => setCode(event.target.value)}
                      label="Code"
                      size="small"
                    />
                    <div>
                      <TextField
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        label="New Password"
                        size="small"
                      />
                    </div>
                    <div>
                      <TextField
                        value={confirmPassword}
                        onChange={(event) =>
                          setConfirmPassword(event.target.value)
                        }
                        label="Confirm Password"
                        size="small"
                      />
                    </div>
                    <Button
                      type="submit"
                      variant="outlined"
                      size="small"
                      sx={{ margin: '8px' }}
                      onClick={resetPassword}
                    >
                      Change password
                    </Button>
                  </>
                )}
              </StepContent>
            </Step>
          )
        })}
      </Stepper>
      {/* Request email code  */}
    </Box>
  )
}

export default ForgotPassword
